import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Button,
    Card,
    CardActions,
    CardContent, Container, Grid,
    Modal,
    Typography
} from "@mui/material";
import {State, useHookstate} from "@hookstate/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, {Fragment} from "react";

type Props = {
    open: State<boolean>
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '100%',
        sm: '90%',
        md: '80%'
    },
    boxShadow: 24,
    p: {
        xs: 1,
        sm: 2,
        md: 3
    },
};

export function Welcome({open}: Props) {
    const expanded = useHookstate<0 | 1 | 2 | 3>(1);

    const handleChange =
        (panel: 1 | 2 | 3) => (_: any, isExpanded: boolean) => {
            if (isExpanded) {
                expanded.set(panel);
            } else {
                expanded.set(0);
            }
        };

    return <Grid>
        <Grid>
            <Typography id="modal-modal-title" variant="h3">
                Programas Eleitorais 2024
            </Typography>
            <Typography id="modal-modal-title">
                <i>Qual o programa eleitoral para mim?</i>
                <br />
            </Typography>
            <Accordion expanded={expanded.value === 1} onChange={handleChange(1)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>
                        <b>Sobre esta ferramenta</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <p>Votar é muito importante e deve ser um ato bastante ponderado, mas a realidade é que os
                            programas dos partidos são looongos e aborrecidos, e os partidos muitas vezes estão
                            mais interesados em atacar-se do que em esclarecer...
                        </p>
                        <p>
                            Utiliza esta ferramenta para calcular os programas com
                            que mais te identificas. No final, passa os olhos neste <a target={"_blank"}
                                                                                       href={"https://expresso.pt/politica/eleicoes/legislativas-2024/comparador-de-programas"}>
                            excelente comparador do jornal Expresso
                        </a> do qual esta ferramenta de "inspirou", mas só no fim, para evitar tendenciosismos.
                        </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.value === 2} onChange={handleChange(2)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>
                        <b>Como funciona</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <b>Navegação</b>
                        <p>
                            No fundo da página tens um pequeno menu.
                            <ul>
                                <li>Início - Regressa a esta página</li>
                                <li>Questionário - Fazer o teste</li>
                                <li>Resultados - Ver os resultados</li>
                            </ul>
                            Podes ver os resultados em qualquer altura que não elimina as tuas respostas atuais. Depois
                            basta clicar em "Questionário" para regressar de novo ao teste.
                        </p>
                        <b>O Teste</b>
                        <p>
                            As propostas dos partidos estão agrupadas por categorias.
                            Para começar podes escolher algumas categorias como favoritas, ao clicar no coração junto ao
                            nome.
                            As tuas categorias favoritas tem um peso maior no resultado final.
                        </p>
                        <p>
                            Dentro de cada categoria vais encontrar o resumo das propostas de cada partido com assento
                            parlamentar atualmente.
                            As propostas não estão identificadas, e os partidos são listados aleatoriamente. Classifica
                            cada uma de acordo com a
                            escala em baixo. Se decidires não responder a uma categoria, esta não conta para o resultado
                            final.
                        </p>
                        <ul>
                            <li>🤮- Horrível / Isto nunca vai funcionar</li>
                            <li>👎- Não gosto / É muito arriscado</li>
                            <li>😐- Não sei / Não percebo / Mais do mesmo</li>
                            <li>👍- Gosto / Tem potencial / Pode funcionar</li>
                            <li>😍- Adoro / É mesmo isto / O meu favorito</li>
                        </ul>
                        <p>
                            Como são bastantes propostas em bastantes areas isto pode demorar um pouco. Mas podes sempre
                            regressar ao site que ele vai-se lembrar das tuas respostas atuais.
                        </p>
                        <b>Resultados</b>
                        <p>
                            Os resultados aparecem como um simples gráfico de barras, ordenado pelo pontuação que destes
                            ao programa de cada partido.
                            O número junto ao nome do partido representa a pontuação, que pode ser negativa, mas o
                            gráfico nunca baixa do zero.
                        </p>
                        <p>
                            Não é possível simplemente listar as propostas com os partidos identidicados para consulta,
                            para isso prefiro que vás ao <a target={"_blank"}
                                                    href={"https://expresso.pt/politica/eleicoes/legislativas-2024/comparador-de-programas"}>
                            site do comparador do jornal Expresso
                        </a> já que os dados aqui são emprestados de lá.
                        </p>
                        <p>
                            Finalmente, podes usar esta nova informação como mais um critério na tua decisão final, e
                            dia 10 de Março não te esqueças.
                        </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded.value === 3} onChange={handleChange(3)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography>
                        <b>Privacidade</b>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <p>Esta ferramenta não recolhe rigorosamente dados nenhuns, exceto simples tracking do Google
                            Analytics
                            para estatísticas de utilização, como pode ser facilmente determinado por alguém com um mínimo
                            conhecimento da
                            web. Por isso podes utilizar com confiança, sabendo que os dados nunca saem do teu
                            dispositivo.
                        </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Grid>
}