import baseSections from '../data.json';
import {Section} from "../model/proposals";

function shuffle<T>(array: T[]) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex > 0) {

        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

export function getSectionsWithRandomizedProposals() {
    const sections = baseSections as Section[];

    return sections.map(section => ({
        ...section,
        parties: shuffle(section.parties).map(p => ({
            ...p,
            rating: 0
        })),
        favorite: false
    })) as Section[];
}

