import {State} from "@hookstate/core";
import {Proposals} from "../model/proposals";
import {Card, CardActions, CardContent, Grid} from "@mui/material";
import {RatingButtons} from "./RatingButtons";

type Props = {
    proposals: State<Proposals>
}

export function PartyProposals({proposals}: Props) {
    return <Card variant={"outlined"}>
        <CardContent>
            <ul>{
                proposals.proposals.map(proposal =>
                    <li key={proposal.value} dangerouslySetInnerHTML={{__html: proposal.value}}></li>
                )
            }</ul>

        </CardContent>
        <CardActions>
            <Grid container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <Grid item><RatingButtons rating={proposals.rating}/></Grid>
            </Grid>
        </CardActions>
    </Card>}