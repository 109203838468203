import React from 'react';
import './App.css';
import {useHookstate} from "@hookstate/core";
import {localstored} from '@hookstate/localstored';
import {getSectionsWithRandomizedProposals} from "./utils/sections";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {Container} from "@mui/material";
import {Welcome} from "./components/Welcome";
import {Shortcuts} from "./components/Shortcuts";
import {Results} from "./components/Results";
import {TakeTest} from "./components/TakeTest";


function App() {
    const sectionsState = useHookstate(
        getSectionsWithRandomizedProposals,
        localstored({
            key: 'sections'
        }));

    const welcomeOpen = useHookstate(true);
    const resultsOpen = useHookstate(false);

    return (
        <Container maxWidth={'md'} sx={{paddingBottom: 8, paddingTop: 2}}>
            {welcomeOpen.value &&
                <Welcome open={welcomeOpen}/>}
            {!welcomeOpen.value && !resultsOpen.value &&
                <TakeTest sectionsState={sectionsState} /> }
            { resultsOpen.value &&
                <Results sections={sectionsState} open={resultsOpen}/>}
            <Shortcuts welcomeOpen={welcomeOpen} resultsOpen={resultsOpen}/>
        </Container>
    );
}

export default App;
