import {ListSections} from "./ListSections";
import React from "react";
import {Section} from "../model/proposals";
import {State} from "@hookstate/core";

type Props = {
    sectionsState: State<Section[]>
}

export function TakeTest({sectionsState}: Props) {
    return (
        <ListSections sections={sectionsState}/>
    );
}