import {Section} from "../model/proposals";
import {State} from "@hookstate/core";
import {SectionDetails} from "./SectionDetails";
import {Container, Grid, Typography} from "@mui/material";

type Props = {
    sections: State<Section[]>
}

export function ListSections({sections}: Props) {

    return <Grid>
        <Grid>
            <Typography id="modal-modal-title" variant="h4">
                Questionário
                <br />
            </Typography>
        </Grid>
        {
            sections.map((section) =>
                <SectionDetails key={section.title.value} section={section}/>
            )
        }
    </Grid>
}