import {Section} from "../model/proposals";
import {State} from "@hookstate/core";
import {Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography} from "@mui/material";
import {PartyProposals} from "./PartyProposals";
import {Favorite, ExpandMore} from "@mui/icons-material";
import {pink} from "@mui/material/colors";

type Props = {
    section: State<Section>
}

export function SectionDetails({section}: Props) {
    const toggleFavorite = (e: React.MouseEvent) => {
        section.favorite.set(f => !f);
        e.stopPropagation();
        return false;
    }

    return (
        <Accordion key={section.value.title}>
            <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography>
                    <IconButton aria-label="Favorito" onClick={toggleFavorite}>
                        {section.favorite.value
                            ? <Favorite sx={{color: pink[500]}} />
                            : <Favorite color={'disabled'} />
                        }
                    </IconButton>
                    <b>{section.value.title}</b>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    section.parties.map(party =>
                        <Grid item xs={12}>
                            <PartyProposals key={party.party.value} proposals={party}/>
                        </Grid>)
                }
            </AccordionDetails>
        </Accordion>
    );
}