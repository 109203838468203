import {State} from "@hookstate/core";
import {ProposalRating} from "../model/proposals";
import {Button, ButtonGroup} from "@mui/material";

type Props = {
    rating: State<ProposalRating>
}

export function RatingButtons({rating}: Props) {
    const buttonVariant = (forValue: ProposalRating): "contained" | "outlined" =>
        forValue === rating.value ? "contained" : "outlined";

    const select = (value: ProposalRating) => () => rating.set(value)

    return (
        <ButtonGroup variant="outlined">
            <Button variant={buttonVariant(-2)} onClick={select(-2)}>🤮</Button>
            <Button variant={buttonVariant(-1)} onClick={select(-1)}>👎</Button>
            <Button variant={buttonVariant(0)} onClick={select(0)}>😐</Button>
            <Button variant={buttonVariant(1)} onClick={select(1)}>👍</Button>
            <Button variant={buttonVariant(2)} onClick={select(2)}>😍</Button>
        </ButtonGroup>
    );
}