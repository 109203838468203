import {State} from "@hookstate/core";
import {Box, Fab, SpeedDial} from "@mui/material";
import {BarChart, Checklist, Home} from "@mui/icons-material";

type Props = {
    welcomeOpen: State<boolean>,
    resultsOpen: State<boolean>
}

export function Shortcuts({welcomeOpen, resultsOpen}: Props) {
    function open(welcome: boolean, results: boolean) {
        welcomeOpen.set(welcome);
        resultsOpen.set(results);
    }
    return <Box sx={{'& > :not(style)': { m: 1 }, position: 'fixed', bottom: 16, right: 16}}>
        {!welcomeOpen.value &&
            <Fab aria-label="Início" size={'small'} variant={'extended'}
                onClick={() => open(true, false)}>
                <Home/>
                Início
            </Fab>
        }
        {
            (welcomeOpen.value || resultsOpen.value) &&

            <Fab color="primary" aria-label="Fazer o Teste" size={'small'} variant={'extended'}
                onClick={() => open(false, false)}>
                <Checklist/>
                Questionário
            </Fab>
        }
        {!resultsOpen.value &&

            <Fab variant="extended" color={"secondary"} size={'small'}
                onClick={() => open(false, true)}>
                <BarChart sx={{mr: 1}}/>
                Resultados
            </Fab>
        }
    </Box>
    // return <>
    //     <SpeedDial
    //         ariaLabel="Ver Resultados"
    //         sx={{position: 'fixed', bottom: 16, right: 16}}
    //         icon={<BarChart/>}
    //         color={"success"}
    //         onClick={() => resultsOpen.set(true)}
    //     />
    //     <SpeedDial
    //         ariaLabel="Início"
    //         sx={{position: 'fixed', bottom: 16, left: 16}}
    //         icon={<Home/>}
    //         onClick={() => welcomeOpen.set(true)}
    //     />
    // </>;
}