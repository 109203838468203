import {State} from "@hookstate/core";
import {Section} from "../model/proposals";
import {Box, Button, Grid, Typography} from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import {getSectionsWithRandomizedProposals} from "../utils/sections";

type Props = {
    sections: State<Section[]>,
    open: State<boolean>
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '100%',
        sm: '90%',
        md: '80%'
    },
    boxShadow: 24,
    p: {
        xs: 1,
        sm: 2,
        md: 3
    },
};

const LABELS = {
    'ps': 'PS',
    'psd': 'PSD',
    'be': 'Bloco de Esquerda',
    'pcp_pev': 'CDU',
    'pan': 'Pan',
    'chega': 'Chega',
    'iniciativa': 'Iniciativa Liberal',
    'livre': 'Livre',
}

const COLORS = {
    'ps': '#e162dd',
    'psd': '#ea9b56',
    'be': '#9fce63',
    'pcp_pev': '#ea3323',
    'pan': '#2f6dba',
    'chega': '#5c2825',
    'iniciativa': '#4fadea',
    'livre': '#081f5c',
}

export function Results({sections, open}: Props) {

    const ratings = sections.flatMap(section =>
        section.parties.map(proposal => ({
                party: proposal.party.value,
                rating: (section.favorite.value ? 2 : 1) * proposal.rating.value
            })
        )
    );

    const result = ratings
        .map(r => r.party)
        .filter((value, index, array) => array.indexOf(value) === index)
        .map(party => ({
            party,
            rating: ratings
                .filter(r => r.party === party)
                .map(r => r.rating)
                .reduce((a, b) => (a as number) + (b as number), 0 as number)
        }))
        .sort((p1, p2) => p2.rating - p1.rating)

    const maxRating = result[0].rating;

    const resultWithPercent = result.map(r => ({
        party: r.party,
        rating: r.rating,
        percent: Math.round(r.rating / maxRating * 100)
    }));

    const resetAndClose = () => {
        open.set(false);
        sections.set(getSectionsWithRandomizedProposals());
    }

    return <Grid>
        <Grid>
            <Typography id="modal-modal-title" variant="h4">
                Resultados
            </Typography>
            {
                resultWithPercent.map(r =>
                    <Box>
                        <Typography><b>{LABELS[r.party]}</b> {r.rating}</Typography>
                        <LinearProgress
                            variant="determinate"
                            value={r.percent}
                            sx={{
                                height: 15,
                                '& .MuiLinearProgress-bar': {
                                    backgroundColor: COLORS[r.party]
                                }
                            }}
                        />
                    </Box>
                )
            }
            <a target={"_blank"}
               href={"https://expresso.pt/politica/eleicoes/legislativas-2024/comparador-de-programas"}>
                visitar artigo original no jornal Expresso
            </a>
        </Grid>
        <Grid>
            <Button onClick={resetAndClose}>Limpar escolhas e regressar ao teste</Button>
        </Grid>
    </Grid>

}